









































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AdminCompanyManagement extends Vue {
  public get params() {
    return { company_id: this.$company.id };
  }
}
