







import { Company } from '@app/models';
import { debounce } from '@bcase/core';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import CompanyNavBar from '../components/1-navigation/company-nav-bar.vue';
import { NavGuard } from '../utils/decorators';

@Component({
  components: { CompanyNavBar },
})
export default class CompanyView extends Vue {
  private debounceSave = debounce(this.save.bind(this), 1000);

  public get companies() {
    return this.$company.list;
  }

  public get company() {
    return this.$company.data;
  }

  public get user() {
    return this.$user.data;
  }

  @Watch('company', { deep: true })
  public watchCompany(next?: Company, prev?: Company) {
    if (next && prev && prev.id === next.id) this.debounceSave(next);
  }

  private async save(company: Company) {
    const form = this.$el.querySelector('bce-form');
    const errors = form ? await form.validate(true) : [];
    if (errors.length) return;

    const c = { ...company };
    await this.$firebase.doc(`company/${c.id}`).set(c, { merge: true });
    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }
}
